import "../styles/globals.css";
import type { AppProps } from "next/app";
import "@dreambigger/design-system/src/styles/index.scss";
import "../styles/antd-overrides.scss";
import "../styles/vouched-overrides.scss";
import { ConfigProvider } from "antd";

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: "proxima-nova",
        },
      }}
    >
      <Component {...pageProps} />
    </ConfigProvider>
  );
}
export default MyApp;
